@import "../_styles/variables";
@import "../_styles/components";
@import "../_styles/classes";

body {
  background-color: $app-color-light-grey !important;
  & .pushable > .pusher {
    @media only screen and (max-width: 64em) {
      overflow: auto;
    }
  }
  & .ui.segment {
    box-shadow: none;
  }
  & .ui.form .fields {
    @media only screen and (max-width: 767px) {
      margin-bottom: 1em;
    }
  }

  & .fieldPusher {
    width: 50%;
  }

  label:after {
    color: $app-color-dark-grey !important;
    font-size: 160%;
    margin: 0 !important;
  }

  .container {
    margin: none;
    //checkboxes
    & .info > table > .ui.checkbox .box,
    .ui.checkbox label {
      margin-right: 1rem;
    }
  }

  .containerButtons {
    padding-bottom: 1rem;
    float: right;
    text-align: center;
  }

  .ui.button {
    &.primary {
      color: $app-color-white !important;
      border: 0.05rem solid $app-color-tertiary !important;
      background-color: $app-color-tertiary !important;
      box-shadow: none !important;
      min-width: 10rem;
      &:hover,
      &:focus {
        background-color: darken(
          $app-color-tertiary,
          $hover-darken-percentage
        ) !important;
      }

      &.inverted {
        color: $app-color-tertiary !important;
        border: 0.05rem solid $app-color-tertiary !important;
        background-color: $app-color-white !important;
        box-shadow: none !important;
        margin-right: 0.3125rem !important;
        min-width: 10rem;
        &:hover,
        &:focus {
          background-color: darken(
            $app-color-white,
            $hover-darken-percentage
          ) !important;
        }
      }
    }
    &.secondary {
      background-color: $app-color-red !important;
      border: 0.05rem solid $app-color-red !important;
      box-shadow: none !important;
      color: $app-color-white !important;
      min-width: 10rem;
      &:hover,
      &:focus {
        background-color: darken(
          $app-color-red,
          $hover-darken-percentage
        ) !important;
      }

      &.inverted {
        background-color: $app-color-white !important;
        border: 0.05rem solid $app-color-red !important;
        box-shadow: none !important;
        color: $app-color-red !important;
        min-width: 10rem;
        &:hover,
        &:focus {
          background-color: darken(
            $app-color-white,
            $hover-darken-percentage
          ) !important;
        }
      }
    }
  }

  .infoBlock {
    box-shadow: 0 2px 4px 0 rgba($app-color-black, 0.15);
    border-radius: 1rem;
    background-color: $app-color-white;
    vertical-align: top;
    min-height: 14rem;
    margin-top: 1rem;

    .infoHeader {
      font-style: normal;
      color: $app-color-dark-grey;
      margin-bottom: 1rem;
      padding: 1rem;
      font-size: 1.3rem;
      border-bottom: 0.07rem solid $app-color-white;
      border-radius: 1rem 1rem 0 0;
      background-color: $app-color-mid-grey;
    }

    .infoWrapper {
      padding: 1rem;
    }

    .details {
      padding: 1rem;
    }
  }

  .accordionComponent {
    background-color: $app-color-white;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    & .accordionHeader {
      color: $app-color-primary !important;
      font-size: 1.5rem !important;
      border-top-width: 2px;
      border-top-color: $app-color-light-grey;
      border-top-style: solid;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      background-color: $app-color-mid-grey;
      padding-left: 1rem !important;
    }
    & .active.content {
      min-height: 10rem;
      padding: 1rem !important;
    }
  }
  & .start-end-date {
    display: inline-flex;
    width: 18rem;
  }
}

.inputLabel,
.ui.form .field > label {
  font-weight: bold;
}

.inputLabel {
  padding-bottom: 0.75rem;
}

/* Only use in printing to PDF */
.printToPDFCheckbox {
  float: left !important;
  text-align: center !important;
  width: 17px !important;
  height: 17px !important;
  background: $app-color-white !important;
  border-radius: 0.21428571rem !important;
  border: 1px solid $app-color-grey !important;
  margin-right: 0.64em !important;
}
.printToPDFCheckboxContent {
  position: relative;
  top: -4px;
  font-weight: 900;
  font-size: 1.2em;
}

@media only screen and (min-width: 1343px) {
  .ui.form.stickyForm {
    margin-top: 6rem;
  }
}
@media only screen and (max-width: 1343px) {
  .ui.form.stickyForm {
    margin-top: 8rem;
  }
}

.stickyHeader {
  position: fixed;
  top: 5.28rem;
  width: 100%;
  z-index: 10;
  background-color: $app-color-light-grey;
  padding-right: 14% !important;
}

.stickyHeader--sidebarOpen {
  padding-right: 17.5rem !important;
}

.stickyHeader--sidebarClosed {
  padding-right: 6rem !important;
}

div.grid.requestFeedback:not(:empty),
.ui.negative.message.ValidationMessage:not(:empty) {
  margin-top: 3rem;
}
