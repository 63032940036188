@import "../../../_styles/variables";

.comparisonModal {
  display: inline-block !important;
  width: 50% !important;
  padding: 1.5rem;
  overflow-wrap: break-word;
  vertical-align: top;
}

.diffArrow {
  font-size: 1.5rem !important;
}

.sessionButton {
  margin-top: 1rem !important;
}
