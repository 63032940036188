@import "../../../../../_styles/variables";

.layoutHeaderSection {
  background-color: $app-color-white;
  height: $topbar-height;
  margin: 0;
  margin-bottom: $topbar-margin;
  & > .topMenuBar {
    height: $topbar-height;
    padding: 1em 0 !important;
    border-width: 1px !important;
    & .menuCollapser {
      z-index: 2;
      &.open {
        left: 18rem !important;
        align-self: center !important;
      }
      &.closed {
        left: 6rem !important;
        align-self: center !important;
      }
    }

    & > .item {
      margin: 0 1em 0 0 !important;
    }
    & .icon {
      color: $app-color-black;
      font-size: 1em;
    }
    & .profile {
      margin-right: 0 !important;
      float: right;
      justify-content: flex-end;
      align-self: center !important;
      & .menuDropdown,
      & .menuDropdown .icon {
        color: $app-color-dark-grey !important;
        & .menuDropdownItem {
          margin-top: 1rem;

          .item {
            margin-right: 0 !important;
          }
        }
      }
      & .usersName {
        font-size: 1.1rem;
        font-style: normal;
        color: $app-color-dark-grey;
        margin: 0 0.6rem 0 0.6rem;
      }
      i.icon.downloadButton {
        margin-right: 1.5rem;
        cursor: pointer;
        font-size: 1.8rem;
      }
    }
  }

  .microsoftLogin {
    margin-right: 2rem;
  }
}
