@import "../../../_styles/variables";

.shareToggle {
  margin-right: 0.35714285714285715rem !important;
  background-color: white !important;
  border: 0.05rem solid $app-color-secondary !important;
  box-shadow: none !important;
  color: $app-color-secondary !important;
  min-width: 10rem;
}
