@import "../../../_styles/variables";

.referralLink {
  text-decoration: underline;
  margin-left: 1rem;
  &:visited {
    color: $app-color-tertiary;
  }
}

.benefitsHeader {
  background-color: $app-color-white !important;
  font-size: 1rem !important;
  font-weight: normal !important;
  font-size: 0.92857143rem !important;
  color: $app-color-primary !important;
}

.viewLabel {
  display: block;
  color: $app-color-dark-grey;
}
